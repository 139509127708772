export const LOGIN_URL = 'auth/signin';

// // Localbase Url
// export const BASE_URL = 'http://192.168.1.100:4000/Admin/';
// export const IMAGE_BASE_URL = 'http://192.168.1.100:4000';


// Staging Base Url
// export const BASE_URL = 'https://api.adweb-staging.com/Admin/';
// export const IMAGE_BASE_URL = 'https://api.adweb-staging.com';

// Production Base Url
export const BASE_URL = 'https://api.themivistore.com/Admin/';
export const IMAGE_BASE_URL = 'https://api.themivistore.com';