// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/')
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product/list'),
    create: path(ROOTS_DASHBOARD, '/product/create/'),
    edit: path(ROOTS_DASHBOARD, '/product/edit/:slug'),
    view: path(ROOTS_DASHBOARD, '/product/view/:slug')
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category/list'),
    create: path(ROOTS_DASHBOARD, '/category/create'),
    update: path(ROOTS_DASHBOARD, '/category/update')
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer/list')
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports/list')
  },
  expenses: {
    root: path(ROOTS_DASHBOARD, '/expenses/list')
  },
  orders: {
    root: path(ROOTS_DASHBOARD, '/orders/latest'),
    list: path(ROOTS_DASHBOARD, '/orders/list'),
    view: path(ROOTS_DASHBOARD, '/orders/view/:orderId'),
    placeOrder: path(ROOTS_DASHBOARD, '/orders/placeorder'),
    customOrder: path(ROOTS_DASHBOARD, '/orders/customorder'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
};
