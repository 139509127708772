import axios from 'axios';
import { BASE_URL } from '../constants/index';
// ----------------------------------------------------------------------

const Axios = axios.create({ baseURL: BASE_URL });

Axios.interceptors.request.use(
  (request) => {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    // Do something before request is sent
    return request;
  },
  (error) => Promise.reject(error)
);

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();

      window.location.replace('/');
      return Promise.reject(error);
    }

    // console.log('error', error.response);
    return Promise.reject(error);
  }
);

export default Axios;
