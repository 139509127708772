import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="50mm" height="19.075394mm" viewBox="0 0 98.983826 19.075394" version="1.1" id="svg5">
        <defs id="defs2" />
        <g id="layer1" transform="translate(-51.562501,-133.82899)">
          <g
            aria-label="ADWeb."
            id="text113"
            style={{
              fontWeight: 'bold',
              fontSize: '25.4px',
              fontFamily: 'Readex Pro',
              inkscapeFontSpecification: 'Readex Pro Bold',
              strokeWidth: '0.264583'
            }}
          >
            <path
              d="m 51.5625,152.625 7.0358,-17.78 h 3.683 l 6.985,17.78 h -4.3688 l -3.3782,-9.017 q -0.1778,-0.5334 -0.4064,-1.143 -0.2032,-0.6096 -0.4064,-1.27 -0.2032,-0.6604 -0.381,-1.27 -0.1778,-0.6096 -0.3048,-1.143 l 0.7366,-0.0254 q -0.1524,0.635 -0.3302,1.2446 -0.1778,0.6096 -0.381,1.2192 -0.2032,0.6096 -0.4318,1.2192 -0.2032,0.5842 -0.4318,1.2192 l -3.3528,8.9662 z m 3.3528,-3.4036 1.3716,-3.302 h 8.128 l 1.3716,3.302 z"
              id="path2648"
            />
            <path
              d="m 71.476112,152.625 v -17.78 h 7.492999 q 1.9558,0 3.5814,0.6604 1.6256,0.635 2.794,1.8288 1.1684,1.1684 1.8034,2.8194 0.635,1.6256 0.635,3.5814 0,1.9812 -0.635,3.6068 -0.635,1.6256 -1.8034,2.8194 -1.1684,1.1684 -2.794,1.8288 -1.6002,0.635 -3.5814,0.635 z m 4.3434,-2.9972 -0.5588,-0.8382 h 3.5814 q 1.041399,0 1.879599,-0.3556 0.8382,-0.3556 1.4224,-1.016 0.6096,-0.6858 0.9144,-1.6002 0.3302,-0.9398 0.3302,-2.0828 0,-1.143 -0.3302,-2.0574 -0.3048,-0.9398 -0.9144,-1.6002 -0.5842,-0.6858 -1.4224,-1.0414 -0.8382,-0.3556 -1.879599,-0.3556 h -3.6576 l 0.635,-0.7874 z"
              id="path2645"
            />
            <path
              d="m 94.234515,152.625 -5.359399,-17.78 h 4.419599 l 2.6924,9.3472 q 0.2286,0.8128 0.381,1.6764 0.1524,0.8382 0.254,1.5748 0.1016,0.7112 0.127,1.1176 l -0.5842,0.0254 q 0.1778,-0.9652 0.2794,-1.7018 0.1016,-0.7366 0.254,-1.3716 0.1524,-0.6604 0.381,-1.3208 l 2.667,-7.0358 h 3.479805 l 2.6162,7.0358 q 0.3048,0.8382 0.4826,1.6002 0.2032,0.7366 0.3048,1.397 0.127,0.635 0.2032,1.27 l -0.5334,0.1016 q 0.0508,-0.4826 0.0762,-0.8636 0.0508,-0.381 0.0762,-0.7366 0.0254,-0.381 0.0762,-0.762 0.0508,-0.4064 0.1524,-0.889 0.1016,-0.508 0.2794,-1.143 l 2.6416,-9.3218 h 4.3434 l -5.3594,17.78 h -3.302 l -4.2418,-10.2108 0.4826,0.0508 -3.886205,10.16 z"
              id="path2642"
            />
            <path
              d="m 121.6919,152.879 q -2.2352,0 -3.9116,-0.889 -1.651,-0.889 -2.5654,-2.4384 -0.889,-1.5748 -0.889,-3.5814 0,-1.5748 0.508,-2.8702 0.508,-1.3208 1.4224,-2.2606 0.9398,-0.9398 2.1844,-1.4478 1.27,-0.5334 2.7686,-0.5334 1.4224,0 2.5908,0.508 1.1684,0.508 2.032,1.4224 0.8636,0.9144 1.3208,2.159 0.4572,1.2446 0.4318,2.7178 l -0.0254,1.1176 h -10.7188 l -0.6096,-2.3114 h 8.001 l -0.4318,0.4572 v -0.508 q -0.0508,-0.635 -0.4064,-1.0922 -0.3302,-0.4826 -0.889,-0.762 -0.5334,-0.2794 -1.2192,-0.2794 -1.016,0 -1.7272,0.4064 -0.6858,0.381 -1.0414,1.1176 -0.3556,0.7366 -0.3556,1.8288 0,1.0922 0.4572,1.9304 0.4826,0.8382 1.3716,1.2954 0.9144,0.4572 2.159,0.4572 0.8382,0 1.524,-0.254 0.6858,-0.254 1.4732,-0.8636 l 1.905,2.6924 q -0.7874,0.6604 -1.6764,1.1176 -0.889,0.4318 -1.8288,0.635 -0.9144,0.2286 -1.8542,0.2286 z"
              id="path2639"
            />
            <path
              d="m 138.2781,152.879 q -0.9144,0 -1.7526,-0.254 -0.8128,-0.254 -1.4986,-0.6604 -0.6858,-0.4318 -1.143,-0.9906 -0.4318,-0.5588 -0.5588,-1.1684 l 0.889,-0.3048 -0.2286,3.0734 h -3.81 V 133.829 h 4.1148 v 8.4582 l -0.8382,-0.2794 q 0.1524,-0.635 0.5842,-1.1938 0.4318,-0.5588 1.0668,-0.9906 0.6604,-0.4572 1.4478,-0.7112 0.7874,-0.254 1.6256,-0.254 1.7526,0 3.0988,0.9144 1.3716,0.9144 2.159,2.5146 0.7874,1.5748 0.7874,3.6068 0,2.032 -0.7874,3.6068 -0.762,1.5748 -2.1082,2.4892 -1.3462,0.889 -3.048,0.889 z m -1.0668,-3.429 q 0.9144,0 1.5748,-0.4318 0.6858,-0.4572 1.0668,-1.2446 0.381,-0.8128 0.381,-1.8796 0,-1.0922 -0.381,-1.8796 -0.3556,-0.8128 -1.0414,-1.2446 -0.6858,-0.4572 -1.6002,-0.4572 -0.9144,0 -1.6002,0.4572 -0.6858,0.4318 -1.0668,1.2446 -0.381,0.7874 -0.381,1.8796 0,1.0668 0.381,1.8796 0.381,0.7874 1.0668,1.2446 0.6858,0.4318 1.6002,0.4318 z"
              id="path2636"
            />
            <path
              d="m 148.33653,152.9044 q -1.0414,0 -1.6256,-0.5842 -0.5588,-0.6096 -0.5588,-1.7018 0,-1.016 0.5842,-1.651 0.6096,-0.6604 1.6002,-0.6604 1.0414,0 1.6256,0.6096 0.5842,0.5842 0.5842,1.7018 0,1.016 -0.635,1.651 -0.6096,0.635 -1.5748,0.635 z"
              style={{ fill: '#2563eb' }}
              id="path2633"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}
