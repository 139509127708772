import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { MIconButton } from '../components/@material-extend';
import { BASE_URL } from '../constants/index';

const authApi = axios.create({
  baseURL: BASE_URL
});

const login = async (credentials) => {
  try {
    const response = await authApi.post('/Auth/Login', credentials);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

const signout = async () => {
  try {
    localStorage.clear();
    return { message: 'Logout Successfully' };
  } catch (error) {
    throw new Error(error);
  }
};

export const useAdminLogin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return useMutation(login, {
    onSuccess: (res) => {
      enqueueSnackbar(res.message, {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      const userData = res.data;
      localStorage.setItem('id', userData.id);
      localStorage.setItem('token', userData.token);
      localStorage.setItem('i18nextLng', 'en');
      window.location.reload();
    },
    onError: (res) => {
      console.log(res);
      enqueueSnackbar(res.message, {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  });
};

export const useAdminSignOut = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return useMutation(signout, {
    onSuccess: (res) => {
      enqueueSnackbar(res.message, {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      window.location.reload();
    },
    onError: (res) => {
      enqueueSnackbar(res.message, {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  });
};

// export const useAdminSignOut = async () => {
//   const queryClient = useQueryClient();
//   const navigate = useNavigate();
//   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
//   return useMutation(signout, {
//     onSuccess: (res) => {
//       enqueueSnackbar(res.message, {
//         variant: 'success',
//         action: (key) => (
//           <MIconButton size="small" onClick={() => closeSnackbar(key)}>
//             <Icon icon={closeFill} />
//           </MIconButton>
//         )
//       });

//       window.location.reload();
//     },
//     onError: (res) => {
//       enqueueSnackbar(res.message, {
//         variant: 'error',
//         action: (key) => (
//           <MIconButton size="small" onClick={() => closeSnackbar(key)}>
//             <Icon icon={closeFill} />
//           </MIconButton>
//         )
//       });
//     }
//   });
// };
